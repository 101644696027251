<!-- 
Button with tooltips
-->

<template>
  <v-tooltip bottom>
    <template v-slot:activator="{ on }">
      <v-btn icon v-on="on" @click.stop="onAction" :disabled="disabled" v-bind="btnBind">
        <v-icon :color="active ? 'primary' : null" v-bind="iconBind">{{ icon }}</v-icon>
      </v-btn>
    </template>
    <span>{{ currentTooltip }}</span>
  </v-tooltip>
</template>

<script>
export default {
  props: {
    tooltip: {
      type: String,
      required: true
    },
    activeTooltip: {
      type: String
    },
    icon: {
      type: String,
      required: true
    },
    toggle: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    iconBind: {
      default: undefined
    },
    btnBind: {
      default: undefined
    }
  },
  data() {
    return {
      active: false
    };
  },
  computed: {
    currentTooltip() {
      return this.active && this.activeTooltip ? this.activeTooltip : this.tooltip;
    }
  },
  methods: {
    onAction() {
      if (this.toggle) {
        this.active = !this.active;
      }
      this.$emit("action-click");
    }
  }
};
</script>
