<template>
  <v-snackbar
    v-model="alertShow"
    :color="getStyle(alert.color, 'sb')"
    :top="'top' == alert.position"
    :right="'right' == alert.position"
    :bottom="'bottom' == alert.position"
  >
    <div class="d-flex">
      <v-icon dark left>{{ getStyle(alert.color, "icon") }}</v-icon>
      <div v-html="alert.msg" style="margin-top: 2px;"></div>
    </div>

    <template v-slot:action="{ attrs }">
      <v-btn text small @click="alertShow = false" v-bind="attrs">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {
    getStyle(status, type) {
      let icon = "mdi-information";
      let snackbarColor = null;
      switch (status) {
        case "success":
          icon = "mdi-check-circle";
          snackbarColor = "info";
          break;
        case "error":
          icon = "mdi-alert";
          snackbarColor = "error";
          break;
      }

      if (type == "icon") {
        return icon;
      } else if (type == "sb") {
        return snackbarColor;
      }

      return null;
    }
  },
  computed: {
    alertShow: {
      get() {
        return this.$store.state.alert.show;
      },
      set() {
        this.$store.commit("offAlert");
      }
    },
    alert: {
      get() {
        return this.$store.state.alert;
      }
    }
  }
};
</script>

<style scoped>
.v-snack {
  z-index: 2000;
}
</style>
