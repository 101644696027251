<template>
  <v-textarea
    v-if="control == 'TEXTAREA'"
    hide-details="auto"
    filled
    dense
    :disabled="disabled"
    ref="input"
    :label="getLabel(label)"
    :value="value"
    @input="$emit('update:value', $event)"
    :rules="getValidationRules(rules)"
    :name="'ctrl_' + attrId"
    v-bind="bind"
  ></v-textarea>

  <div v-else-if="control == 'RADIO'">
    <v-label>{{ getLabel(label) }}</v-label>
    <v-radio-group
      :value="value"
      :disabled="disabled"
      @change="$emit('update:value', $event)"
      hide-details="auto"
      :rules="getValidationRules(rules)"
      :name="'ctrl_' + attrId"
      v-bind="bind"
    >
      <v-radio
        v-for="item in attrRef"
        ref="input"
        :key="item.value"
        :label="item.text"
        :value="item.value"
      ></v-radio>
    </v-radio-group>
  </div>

  <div v-else-if="control == 'CHECKBOX'">
    <template v-if="attrRef">
      <v-label>{{ getLabel(label) }}</v-label>
      <v-checkbox
        hide-details="auto"
        style="margin-top: 0;"
        :disabled="disabled"
        v-for="item in attrRef"
        :key="item.value"
        :label="item.text"
        :value="item.value"
        :input-value="value"
        @change="$emit('update:value', $event)"
        :name="'ctrl_' + attrId"
      ></v-checkbox>
    </template>
    <v-checkbox
      v-else
      hide-details="auto"
      style="margin-top: 0;"
      :disabled="disabled"
      :label="getLabel(label)"
      :value="value"
      @change="$emit('update:value', $event)"
      :name="'ctrl_' + attrId"
    ></v-checkbox>
  </div>

  <v-menu
    v-else-if="attrType == 'D'"
    v-model="dateMenu"
    :close-on-content-click="false"
    :nudge-right="40"
    transition="scale-transition"
    offset-y
    max-width="290px"
    min-width="290px"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        :append-icon="
          control == 'DATETIME'
            ? 'mdi-calendar-clock'
            : control == 'TIME'
            ? 'access_time'
            : 'mdi-calendar'
        "
        hide-details="auto"
        dense
        ref="input"
        :name="'ctrl_' + attrId"
        :rules="getValidationRules(rules)"
        :value="dateValue"
        :label="getLabel(label)"
        readonly
        :disabled="disabled"
        v-bind="{ ...bind, ...attrs }"
        v-on="on"
        @click="dtPicker.step = 1"
      ></v-text-field>
    </template>
    <v-date-picker
      v-if="(control == 'DATETIME' && dtPicker.step == 1) || control == 'DATE' || !control"
      v-model="dtPicker.date"
      @input="onDatePicked($event, 'date')"
    ></v-date-picker>
    <v-time-picker
      v-if="((control == 'DATETIME' && dtPicker.step == 2) || control == 'TIME') && dateMenu"
      v-model="dtPicker.time"
      full-width
      v-bind="bind"
      @click:minute="onDatePicked($event, 'time')"
    ></v-time-picker>
  </v-menu>

  <v-select
    v-else-if="attrType == 'L'"
    hide-details="auto"
    dense
    :menu-props="{ maxHeight: '400' }"
    :items="getSelectList(attrRef)"
    ref="input"
    :label="getLabel(label)"
    :value="getActiveVal(value)"
    :multiple="multiValue"
    :disabled="disabled"
    :rules="getValidationRules(rules)"
    @change="handleChange($event)"
    :name="'ctrl_' + attrId"
    v-bind="bind"
  ></v-select>

  <v-text-field
    v-else
    hide-details="auto"
    dense
    :type="control == 'PASSWORD' ? 'password' : attrType == 'N' ? 'number' : 'text'"
    :label="getLabel(label)"
    ref="input"
    :value="getActiveVal(value)"
    :disabled="disabled"
    @input="handleChange"
    :rules="getValidationRules(rules)"
    :name="'ctrl_' + attrId"
    v-bind="bind"
  ></v-text-field>
</template>

<script>
export default {
  model: {
    event: "update:value"
  },
  props: [
    "noPrependEmptyVal", //prepend empty value to select
    "attrId",
    "attrType",
    "attrRef",
    "label",
    "control",
    "value",
    "activeIndex", //for multiple attr that only can have one val active at a time, e.g. address_id
    "multiValue",
    "multiAttr",
    "disabled",
    "bind",
    "rules"
  ],
  data() {
    return {
      actualVal: undefined,
      displayVal: null,
      dateMenu: null,
      dtPicker: {
        time: "",
        date: "",
        step: 1
      }
    };
  },
  computed: {
    dateValue() {
      let value = this.value;
      if (this.control == "DATETIME") {
        if (this.dtPicker.time && this.dtPicker.date) {
          value = this.dtPicker.date + " " + this.dtPicker.time;
        }
      } else if (this.control == "TIME" && this.dtPicker.time) {
        value = this.dtPicker.time;
      } else if ((this.control == "DATE" || !this.control) && this.dtPicker.date) {
        value = this.dtPicker.date;
      }
      return value;
    }
  },
  methods: {
    onDatePicked(e, type) {
      let value = e;
      let terminate = false;
      if (type == "date") {
        //this.dtPicker.date = value;
        if (this.control == "DATETIME") {
          this.dtPicker.step = 2;
        } else {
          terminate = true;
        }
      } else {
        //this.dtPicker.time = value;
        if (this.control == "DATETIME") {
          if (this.dtPicker.date && this.dtPicker.time) {
            value = this.dtPicker.date + " " + this.dtPicker.time;
            terminate = true;
          }
        } else {
          value = this.dtPicker.time;
          terminate = true;
        }
      }

      if (terminate) {
        this.dateMenu = false;
        this.$emit("update:value", value);
      }
    },
    focusIfInvalid() {
      if (!this.$refs.input.valid) {
        let focusInput = Array.isArray(this.$refs.input)
          ? this.$refs.input[0].$refs.input
          : this.$refs.input;
        if (focusInput) focusInput.focus();
        return true;
      }
      return false;
    },
    handleChange(e) {
      if (this.multiAttr) {
        if (!this.actualVal) {
          this.actualVal = {};
        }
        this.actualVal[this.activeIndex] = e;
        this.displayVal = e;
      } else {
        this.actualVal = e;
      }
      this.$emit("update:value", this.actualVal);
    },
    getLabel(label) {
      if (Array.isArray(this.rules) && this.rules.includes("mandatory")) {
        label += "*";
      }
      return label;
    },
    getSelectList(items) {
      // append empty value to select list only if
      // 1. multivalue
      // 2. no mandatory rules set
      // 3. not explicitly set to no
      if (
        Array.isArray(items) &&
        !this.multiValue &&
        ((Array.isArray(this.rules) && !this.rules.includes("mandatory")) || !this.rules) &&
        !this.noPrependEmptyVal
      ) {
        return [{ text: "", value: null }, ...items];
      }
      return items;
    },
    getActiveVal(value) {
      this.actualVal = value;

      if (this.multiAttr) {
        if (value === null) {
          this.displayVal = null;
          this.actualVal = undefined;
        } else if (value && typeof value == "object") {
          let activeIndex = this.activeIndex;
          if (!(activeIndex > -1)) {
            activeIndex = 0;
          }
          this.displayVal = value[activeIndex];
        }

        return this.displayVal;
      } else {
        if (value && this.multiValue && !Array.isArray(value)) {
          value = value.split(",");
        }

        return value;
      }
    },
    getValidationRules(rules) {
      let functionRules = [];
      if (this.disabled || !rules) return functionRules;
      if (Array.isArray(rules)) {
        rules.forEach((rule) => {
          switch (rule) {
            case "mandatory":
              functionRules.push(
                (v) =>
                  (Array.isArray(v) && v.length > 0) || (!Array.isArray(v) && !!v) || "Required"
              );
              break;
            case "email":
              functionRules.push((v) => /.+@.+\..+/.test(v) || !v || "Invalid email format");
              break;
            case "url":
              functionRules.push(
                (v) =>
                  /^((?:https?:\/\/)?[^./]+(?:\.[^./]+)+(?:\/.*)?)$/i.test(v) ||
                  !v ||
                  "Invalid url format"
              );
              break;
            default:
              functionRules.push(rule);
          }
        });
      }

      return functionRules;
    }
  }
};
</script>
